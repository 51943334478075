<template>
    <div class="ce-content">

        <div class="row ce-row">

            <!-- First col ------------------------>
            <div class="col-md-12">

                <!-- ce-content-inner ------------------------------>
                <div class="ce-content-inner">
                    <div class="row roles-page-wrap client-scopes-wrap">
                        <div class="col-md-12 roles-page-header">
                            <h3 class="ce-create-client-list-card title">Clients Scopes</h3>
                            <div class="ce-create-role-btn-wrap">
                                <!--<button class="btn ce-btn-create-role-submit" data-toggle="modal"
                                        data-target="#ce-create-scopes">
                                    Create new
                                </button>-->
                            </div>
                        </div>
                        
                        <div class="col-md-12">
                            <!-- Create role Modal -->
                            <div class="modal fade ce-create-scopes" id="ce-create-scopes" tabindex="-1" role="dialog" ref="createModal"
                                 aria-labelledby="ce-create-role-modal" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 v-if="isEdit" class="modal-title" id="ce-role-edit-modal">Edit Scope</h5>
                                            <h5 v-else class="modal-title" id="ce-role-create-modal">Create New Scope</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="reset()">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                          <div v-if="isEdit" class="ce-content-inner client-roles-wrap">
                                            <div class="cs-name">
                                              <label for="cs-name-input">Scope</label>
                                              <input type="text" v-model="updateClientScopeData.name" id="cs-name-edit-input" placeholder="">
                                            </div>
                                            <div class="cs-description">
                                              <label for="cs-name-input">Description</label>
                                              <textarea name="" v-model="updateClientScopeData.description" id="cs-description-edit-input1" cols="30" rows="10" placeholder=""></textarea>
                                            </div>
                                          </div>
                                            <div v-else class="ce-content-inner client-roles-wrap">
                                                <div class="cs-head">
                                                    <div class="scope-client-select">
                                                        <label for="scope-client-select-id">Client: </label>
                                                      <select v-model="newClientScopes.sso_client_id" name="" id="roles-client-select-id">
                                                        <option v-for="data in clientList" :key="data.id" :value="data.id">{{data.name}}</option>
                                                      </select>
                                                    </div>
                                                    <div class="cs-name">
                                                        <label for="cs-name-input">Scope</label>
                                                        <input type="text" v-model="tempClientScope.name" id="cs-name-input" placeholder="Enter Scope Name">
                                                    </div>
                                                    <div class="cs-description">
                                                        <label for="cs-name-input">Description</label>
                                                        <textarea name="" v-model="tempClientScope.description" id="cs-description-input" cols="30" rows="10" placeholder="Enter Description"></textarea>
                                                    </div>
                                                    <div class="add-scope-action">
                                                        <button class="btn btn-add-scope" v-on:click="addScopeDetail()" >Add</button>
                                                    </div>
                                                    <!--<div class="upload-csv">
                                                        <form>
                                                            <div class="form-group">
                                                                <label for="exampleFormControlFile1">Upload csv</label><br>
                                                                <input type="file" class="form-control-file" id="exampleFormControlFile1">
                                                            </div>
                                                        </form>
                                                    </div>-->
                                                </div>

                                                <div class="select-cr">
                                                    <div class="scr-checkbox-wrap">
                                                        <div v-for="data in newClientScopes.scopeDetail" :key="data.name" class="scope-list">
                                                            <p class="scope-list-name">{{data.name}}</p>
                                                            <p>{{data.description}}</p>
                                                            <i class="fa fa-times-circle-o" v-on:click="removeRow(data)" aria-hidden="true"></i>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal-footer">
                                            <button v-on:click="reset()" type="button" class="btn btn-danger"
                                                    data-dismiss="modal">Cancel
                                            </button>
                                            <button v-if="isEdit" v-on:click="saveEditScope()" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Update
                                            </button>
                                            <button v-else v-on:click="createClientScope" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Create role Modal End -->

                        </div>
                    </div>

                    <div class="row new-list-table user-list-table">
                        <div class="col-12">
                            <div class="list-table-header">
                                <div class="list-header id">ID</div>
                                <div class="list-header name">Name</div>
                                <div class="list-header description">Description</div>
                                <div class="list-header description">App</div>
                                <div class="list-header created">Created At</div>
                                <div class="list-header actions">Actions</div>
                            </div>

                            <div class="list-table-body">
                                <div class="list-row" v-for="(scope,index) in clientScopes" :key="scope.id">
                                    <div class="list-body id">{{index+1}}</div>
                                    <div class="list-body name">{{scope.name}}</div>
                                    <div class="list-body description">{{scope.description}}</div>
                                    <div class="list-body description">{{scope.sso_clients ? scope.sso_clients.name : 'SSO'}}</div>
                                    <div class="list-body created">{{scope.created_at}}</div>
                                    <div class="list-body actions">
                                        <!--<div class="edit">
                                            <span name="Edit Scope" @click="editScope(scope)" title="EDIT ROLE" data-toggle="modal" data-target="#ce-create-scopes">
                                                <i class="cts-configure cts_recipient_icon"></i>
                                            </span>
                                        </div>-->
                                        <div class="delete">
                                            <span name="Delete Role" @click="deleteScope(scope)" title="DELETE ROLE">
                                                <i class="cts-delete cts_recipient_icon"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                </div>
                <!-- ce-content-inner End ------------------------>

            </div>
            <!-- First col End -------------------------------->

        </div>
    </div>
</template>

<style>
.client-scopes-wrap .roles-page-header .ce-create-role-btn-wrap {
    left: 195px;
}
#ce-create-scopes .modal-content .modal-body .cs-name label, .cs-description label, #ce-create-scopes .modal-content .modal-body .cr-remark label, #ce-create-scopes .modal-content .modal-body .scope-client-select label {
    width: 25%;
}
#ce-create-scopes .modal-content .modal-body .cs-name input, .cs-description input, #ce-create-scopes .modal-content .modal-body .cr-remark input, #ce-create-scopes .modal-content .modal-body .scope-client-select select {
    width: 75%;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
    border-radius: 0.25rem;
    font-weight: 600;
    background: #f8f9fa;
    border: 1px solid #00000033;
}
.cs-head .scope-client-select {
    margin-bottom: 18px;
    margin-top: 0px;
    width: 100%!important;
}
.cs-head .cs-description textarea {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 13px;
    background: #f8f9fa;
    border: 1px solid #00000033;
}
.cs-head .cs-name, .cs-head .cs-description {
    display: inline-block;
    width: calc(40% - 12px)!important;
    vertical-align: top;
    margin-right: 12px;
}
#ce-create-scopes .modal-content .modal-body .cs-name input {
    width: 100%;
    margin-top: 0;
}
.cs-head .cs-name label, .cs-head .cs-description label {
    display: block;
    margin-bottom: 10px;
}
.add-scope-action {
    display: inline-block;
    margin-top: 23px;
    width: 20%;
}
.add-scope-action button {
    padding: 9px 0px;
    background: #00ccff;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    text-align: center;
}
.upload-csv {
    margin-top: 15px;
}
.upload-csv label {
    display: inline-block;
    margin-bottom: 10px;
}
.upload-csv input {
    display: inline-block;
    width: 200px;
    background: transparent!important;
    font-size: 14px;
}
#ce-create-scopes .modal-content .modal-body .select-cr {
    margin-top: 30px;
}
#ce-create-scopes .modal-content .modal-body .scr-checkbox-wrap {
    margin-top: 15px;
    margin-bottom: 25px;
    border: 2px solid #e4e5ea4d;
    padding: 0px;
    padding-bottom: 0;
    max-height: 200px;
    overflow-y: auto;
}
#ce-create-scopes .modal-content .modal-body .select-cr .scope-list {
    position: relative;
    cursor: default!important;
}
#ce-create-scopes .modal-content .modal-body .select-cr .scope-list:hover {
    background: #e4e5ea4d;
}
#ce-create-scopes .modal-content .modal-body .select-cr .scope-list-name {
    width: 35%;
    padding-right: 10px;
}
#ce-create-scopes .modal-content .modal-body .select-cr p {
    width: 65%;
    display: inline-block;
    padding-right: 30px;
    cursor: default;
}
#ce-create-scopes .modal-content .modal-body .select-cr .fa {
    position: absolute;
    top: 10px;
    font-size: 20px;
    right: 8px;
    cursor: pointer;
    opacity: 0.5;
}
#ce-create-scopes .modal-content .modal-body .select-cr .fa:hover {
    opacity: 1;
}
#ce-create-scopes .modal-content .modal-body .select-cr .scope-list {
    padding-left: 10px;
    padding-top: 13px;
    padding-bottom: 14px;
    display: block;
    cursor: pointer;
    font-size: 13px;
    border-bottom: 1px solid #efefef;
    font-weight: 600;
    opacity: 0.7;
    z-index: 1;
    position: relative;
}
#ce-create-scopes .modal-content .modal-body .cr-remark {
    margin-bottom: 20px;
}
.ce-main-wrapper .ce-wrapper .ce-content .ce-content-inner .ce-create-role-btn-wrap .ce-btn-create-role-submit {
    width: 180px;
    height: 45px;
    background: #00ccff;
    color: #fff;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
}
.cts_recipient_icon{
    color:#2cc4ef;
    font-size:18px;
    cursor: pointer;
    margin: 7px;
}
.multiselect__tags .multiselect__single, .send_form .input_box input, .multiselect__tags .multiselect__placeholder {
    font-size: 14px !important;
    color: #656568 !important;
}
.multiselect__tags {
    border: 1px solid #bfbfbf !important;
    padding-left: 15px;
}
.multiselect__tags .multiselect__single {
    overflow: hidden;
}
.multiselect .multiselect__tags {
    height: 40px;
}
.cts-group.cts_recipient_icon {
    font-weight: bold!important;
}
#ce-create-scopes .modal-content .modal-body .ce-content-inner.client-roles-wrap {
    padding-bottom: 20px;
}
#ce-create-scopes .modal-content .modal-body .cs-name {
    padding-bottom: 30px;
}
#ce-create-scopes .modal-content .modal-body .cs-name #cs-name-edit-input {
    width: 75%;
}
#ce-create-scopes .modal-content .modal-body #cs-description-edit-input1 {
    width: 75%;
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
    border-radius: 0.25rem;
    font-weight: 600;
    background: #f8f9fa;
    border: 1px solid #00000033;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
    import Api from '../includes/Api';
    import moment from 'moment';
    //import Multiselect from "vue-multiselect";

    export default {
        components: {
            //Multiselect,
        },
        data() {
            return {
              isEdit: 0,
              editIndex : null,
              tempClientScope: {
                name: null,
                description: null
              },
              newClientScopes: {
                scopeDetail : [],
                sso_client_id: null
              },
              updateClientScopeData : {
                id: null,
                name: null,
                description: null,
                sso_client_id: null
              },
              clientScopes: [],
              clientList: [],
            }
        },
        methods: {
            createClientScope() {

              this.$confirm(
                  {
                    message: 'Are you sure you want to create this scope?',
                    button: {
                      no: "No",
                      yes: "Yes"
                    },
                    callback: confirm => {
                      if (confirm){

                        let root = this;
                        if (root.newClientScopes.name === ''){
                          Api.alert("warning", "Name cannot be empty.");
                        }
                        else{
                          Api.post('/add-client-scope', root.newClientScopes).then((response) => {
                            if (response.data.status === 'success') {
                              this.reset();
                                if( response.data.data.length > 0  ){
                                  this.getAllClientScopes();
                                }
                                this.reset();
                              Api.alert('success', response.data.message);

                            }
                          });
                        }

                      }
                    }
                  }
              )

            },
            getAllClientScopes() {
                let root = this;
                Api.get('/list-client-scope').then((response) => {
                    response.data.data.forEach(item => {
                        item.created_at = moment(item.created_at).format('lll');
                    });
                    root.clientScopes = response.data.data;
                });
            },
            deleteScope(scope) {
                this.$confirm(
                    {
                        message: 'Are you sure you want to delete this scope?',
                        button: {
                            no: "No",
                            yes: "Yes"
                        },
                        callback: confirm => {
                            if (confirm){
                                Api.get('/delete-client-scope/'+scope.id).then((resp) => {
                                    if (resp.data.status === 'success') {
                                        Api.alert('success', resp.data.message);
                                    }
                                    let index = this.clientScopes.indexOf(scope);
                                    if (index !== -1) {
                                        this.clientScopes.splice(index, 1);
                                    }
                                });
                            }
                        }
                    }
                )
            },
            saveEditScope(){
              this.$confirm(
                  {
                    message: 'Are you sure you want to update this scope?',
                    button: {
                      no: "No",
                      yes: "Yes"
                    },
                    callback: confirm => {
                      if (confirm){

                        let root = this;
                        if (root.updateClientScopeData.name === ''){
                          Api.alert("warning", "Name cannot be empty.");
                        }
                        else{
                          let data = {
                            "id": root.updateClientScopeData.id,
                            "name": root.updateClientScopeData.name,
                            "description": root.updateClientScopeData.description,
                            "sso_client_id": root.updateClientScopeData.sso_client_id
                          };
                          Api.post('/edit-client-scope', data).then((response) => {
                            if (response.data.status === 'success') {
                              if( response.data.data !== null || response.data.data !== undefined ){
                                this.$set(this.clientScopes,this.editIndex,response.data.data);
                              }
                              this.reset();
                              Api.alert('success', response.data.message);
                            }
                          });
                        }

                      }
                    }
                  }
              )
            },
            editScope(scope) {
              this.isEdit=1;
              this.editIndex = this.clientScopes.indexOf(scope);
              Api.get('/list-client-scope-by-id/'+scope.id).then((resp) => {
                if (resp.data.status === 'success') {
                  this.updateClientScopeData.id = resp.data.data.id;
                  this.updateClientScopeData.name = resp.data.data.name;
                  this.updateClientScopeData.description = resp.data.data.description;
                  this.updateClientScopeData.sso_client_id = resp.data.data.sso_client_id;
                }
              });
            },
            getAllClients(){
                let root = this;
                Api.get('/list-access-client').then((response) => {
                    root.clientList = response.data.data;
                });
            },
            addScopeDetail() {

                if ( this.tempClientScope.name == null || this.tempClientScope.name === ''  || this.tempClientScope.name === undefined) {
                  alert('Name cannot be null. Please try again.');
                } else if ( this.tempClientScope.description == null || this.tempClientScope.description === ''  || this.tempClientScope.description === undefined) {
                  alert('Description cannot be null. Please try again.');
                } else {
                  let data = {
                    name: this.tempClientScope.name,
                    description: this.tempClientScope.description
                  }
                  this.newClientScopes.scopeDetail.push(data);
                  this.tempClientScope.name=null;
                  this.tempClientScope.description = null
                }
            },
            removeRow(data){
              this.newClientScopes.scopeDetail.splice(this.newClientScopes.scopeDetail.indexOf(data),1);
            },
          reset(){
            this.isEdit=0;
            this.editIndex=null;
            this.updateClientScopeData.id=null;
            this.updateClientScopeData.name=null;
            this.updateClientScopeData.description=null;
            this.updateClientScopeData.sso_client_id=null;
            this.newClientScopes.id=null;
            this.newClientScopes.sso_client_id=null;
            this.newClientScopes.scopeDetail = [];
            this.tempClientScope.name=null;
            this.tempClientScope.description=null;
          }
        },
        created() {
          this.getAllClientScopes();
          this.getAllClients();
        }
    }
</script>
